import React, { createContext, useState, useEffect, useContext } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { auth, db } from "../firebase/firebaseConfig"; // Import db directly
import { EnvContext } from "./EnvContext";

// Create the UserContext
export const UserContext = createContext(null);

export const UserContextProvider = ({ children }) => {
  //
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Added loading state

  useEffect(() => {
    let unsubscribeFirestore = null;

    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser && currentUser.uid) {
        // Use db directly

        const userRef = doc(db, `Users/UsersList/DataBase`, currentUser.uid);

        // Get user data from Firestore
        unsubscribeFirestore = onSnapshot(userRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();

            setUser({ uid: currentUser.uid, ...userData, ...currentUser });
          } else {
            console.error("User document does not exist!");
          }
          setLoading(false); // Set loading to false once user data is fetched
        });
      } else {
        setUser(null);
        if (unsubscribeFirestore) {
          unsubscribeFirestore();
          unsubscribeFirestore = null;
        }
        setLoading(false); // Set loading to false if no user is logged in
      }
    });

    // Cleanup subscription on unmount
    return () => {
      unsubscribeAuth();
      if (unsubscribeFirestore) {
        unsubscribeFirestore();
      }
    };
  }, []);

  return <UserContext.Provider value={{ user, loading }}>{children}</UserContext.Provider>;
};
