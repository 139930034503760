import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useFirestore } from "../firebase/FirestoreContext";
import { currentProductPrice } from "../functions/currentProductPrice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStarOfLife } from "@fortawesome/free-solid-svg-icons";
import addToCart from "../functions/addToCart";
import "../styles/global.css"; // Import global styles
import ListingPriceFormater from "./ListingPriceFormater";
import { EnvContext } from "../context/EnvContext";
import Cookies from "js-cookie";
import config from "../config";
import { logAnalyticsEvent } from "../firebase/firebaseConfig";

const ListItem = ({ product, index, dataMultiple }) => {
  //
  const environment = useContext(EnvContext);
  //
  const navigate = useNavigate();
  const firestore = useFirestore();
  const auth = getAuth();
  const [addedToCart, setAddedToCart] = useState(false);
  const [priceInfo, setPriceInfo] = useState({});

  // environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  // Fetch the price information when the component mounts
  useEffect(() => {
    const fetchPriceInfo = async () => {
      const result = await currentProductPrice(product);
      setPriceInfo(result); // Store the result in the state
    };

    fetchPriceInfo();
  }, [product]); // Dependency on the product, so it runs when the product changes

  const handleAddToCart = async (listingID) => {
    try {
      setAddedToCart(true);
      const user = auth.currentUser || null; // Get the current authenticated user

      await addToCart(listingID, user, firestore, 1, collectionPath);

      // Reset addedToCart state after 1 second
      setTimeout(() => {
        setAddedToCart(false);
      }, 1000);
    } catch (error) {
      console.error("Error adding to cart: ", error);
      // Reset addedToCart state in case of error
      setAddedToCart(false);
    }
  };
  const saveScrollPosition = () => {
    const scrollY = window.scrollY; // Get the current Y scroll position
    Cookies.set("ShopPageScrollPosition", scrollY); // Save it in localStorage
  };

  const handleNavigate = () => {
    //
    logAnalyticsEvent("select_content", {
      content_type: "product",
      item_id: product.seller_product_id,
      item_name: product.product_name,
      environment: environment,
    });
    //
    saveScrollPosition(); // Save scroll position before navigating
    navigate(`Listing/${product?.seller_product_id}`); // Navigate to the product detail page
  };

  //const rating = product?.rating?.rating ? parseFloat(product.rating.rating).toFixed(1) : "0.0";
  //const votes = product?.rating?.votes || 0;

  // calculate rating and initial rating
  let rating = parseFloat(product?.rating?.rating) || 0;
  let votes = parseFloat(product?.rating?.votes) || 0;
  let initialRating = parseFloat(product?.rating_initial?.rating) || 0;
  let initialVoting = parseFloat(product?.rating_initial?.voting) || 0;

  // if all is 0
  if (votes == 0 && initialVoting == 0) {
    rating = initialRating;
    votes = initialVoting;
  } else {
    rating = parseFloat((rating + initialRating * initialVoting) / (initialVoting + votes)).toFixed(1);
    votes = votes + initialVoting;
  }

  let renderPrice;
  let renderCounter;

  return (
    <div
      style={{
        ...styles.listItemContainer,
        ...(index % 2 === 0 ? styles.borderRight : {}),
      }}
    >
      <div
        style={{ ...styles.listItem, position: "relative" }}
        onClick={() => {
          handleNavigate();
          //navigate(`Listing/${product?.seller_product_id}`);
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            height: "25px",
          }}
        >
          {priceInfo && product && product?.listing_stock_amount !== 0 ? (
            <ListingPriceFormater
              priceInfo={priceInfo}
              product={product}
              type={"sooperdeal_counter_with_flames"}
              containerStyle={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 0,
                fontSize: ".8rem",
                padding: "5px 0",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                borderBottom: ".5px solid rgba(100,100,100,.3)",
                backgroundColor: "#f7ff00",
              }}
            />
          ) : null}
        </div>
        <div style={styles.listItemImageContainer}>
          <img
            src={product?.images && product?.images?.length > 0 ? product?.images[0] : "/images/no_product_image.png"}
            alt={product?.name || "Product"}
            style={styles.listItemImage}
          />
        </div>
        <div style={{ ...styles.listItemContent }}>
          <div style={styles.productName.container}>
            <div style={{ display: "flex", height: 34 }}>
              <h3
                style={{
                  ...styles.productName.name,
                  // max two lines
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {product?.product_name || ""}
              </h3>
            </div>
            <div style={styles.productName.rating}>
              <FontAwesomeIcon icon={faStarOfLife} style={styles.productName.iconStar} />
              <span style={styles.productName.ratingText}>
                {rating || "0.0"}
                {` (${votes})`}
              </span>
            </div>
            <div style={{ fontSize: 12, fontWeight: "600", padding: "5px 10px" }}>{product?.brand_name}</div>
          </div>
        </div>

        <div style={styles.addToBag.container}>
          <div style={{ ...styles.addToBag.priceSection }}>
            {priceInfo && product ? <ListingPriceFormater priceInfo={priceInfo} product={product} type={"prices"} /> : null}
          </div>
          <button
            style={{
              ...styles.addToBag.addToCart,
              ...(addedToCart ? styles.addToBag.addedToCart : {}),
              position: "relative",
              cursor: product?.listing_stock_amount == 0 || addedToCart ? "default" : "pointer",
              color: product?.listing_stock_amount == 0 || addedToCart ? "silver" : "black",
            }}
            onClick={(e) => {
              e.stopPropagation();
              product?.listing_stock_amount != 0 && handleAddToCart(product?.seller_product_id);
            }}
            disabled={product?.listing_stock_amount == 0 || addedToCart}
          >
            {product?.listing_stock_amount == 0 ? "Sold Out" : addedToCart ? "Added!" : "Add to bag"}
          </button>
        </div>
      </div>
    </div>
  );
};

// Styles object for ListItem component
const styles = {
  listItemContainer: {
    border: "0.1px solid rgba(100,100,100,.3)",
    borderLeft: "none",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    margin: 0,
  },
  borderRight: {
    borderRight: "1px solid #ddd",
  },
  listItem: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    height: "100%", // Ensures the container uses full height
    justifyContent: "space-between", // Distributes items with space between
  },
  listItemImageContainer: {
    display: "flex",
    height: "130px",
    margin: "10px 10px 5px 10px",
  },
  listItemImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  listItemContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },
  productName: {
    container: {
      flex: 1,
      paddingBottom: "10px",
    },
    name: {
      fontSize: "16px",
      fontWeight: 400,
      color: "black",
      margin: 0,
      padding: "0 10px",
      fontSize: "14px",
    },
    rating: {
      display: "flex",
      alignItems: "center",
      marginTop: "5px",
      padding: "0 10px",
    },
    iconStar: {
      color: "black",
      fontSize: "10px",
      marginTop: "-2px",
    },
    ratingText: {
      fontWeight: 400,
      fontSize: "12px",
      color: "black",
      marginLeft: "5px",
    },
  },
  /* Add to bag section */
  addToBag: {
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "50px",
      alignSelf: "flex-end", // Ensures it's positioned at the bottom of the list item
      marginTop: "auto",
      width: "100%",
      fontSize: "15px",
      fontWeight: 800,
    },
    priceSection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: "10px",
      height: "100%",
      flex: 1,
    },
    priceOffer: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      fontWeight: 700,
    },
    originalPrice: {
      color: "gray",
      fontSize: "12px",
      textDecoration: "line-through",
      marginRight: "5px",
    },
    salePrice: {
      color: "#1fd400",
      fontWeight: 700,
    },
    price: {
      color: "#06ca49",
      fontWeight: 700,
    },
    addToCart: {
      flex: 1,
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#f4f2f2",
      width: "100px",
      textAlign: "center",
      fontSize: "11px",
      fontWeight: 600,
      cursor: "pointer",
      border: "none",
      color: "black",
      transition: "background-color 0.3s ease",
    },
    addedToCart: {
      backgroundColor: "#000",
      color: "white",
    },
  },
};

export default ListItem;
