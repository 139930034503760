// src/functions/countCartItems.js

import { collection, query, where, onSnapshot } from "firebase/firestore";
import { EnvContext } from "../context/EnvContext";
import config from "../config";

const countCartItems = (firestore, userID, updateCartCount, collectionPath) => {
  if (!userID) {
    // If no user ID is provided, set total items to 0
    updateCartCount(0);
    return;
  }

  const cartRefPath = `${collectionPath}Carts/CartsList/DataBase`;
  const cartQuery = query(collection(firestore, cartRefPath), where("user_id", "==", userID), where("status", "==", "ordering"));

  const unsubscribe = onSnapshot(cartQuery, (querySnapshot) => {
    if (!querySnapshot.empty) {
      const cartDoc = querySnapshot.docs[0]; // Assuming there's only one cart per user
      const itemsRef = collection(firestore, `${cartRefPath}/${cartDoc.id}/Items`);

      onSnapshot(itemsRef, (itemsSnapshot) => {
        let numberOfItemsInCart = 0;
        itemsSnapshot.forEach((doc) => {
          numberOfItemsInCart += Number(doc.data().quantity);
        });

        // Update the cart count state
        updateCartCount(numberOfItemsInCart);
      });
    } else {
      // If no cart is found, set total items to 0
      updateCartCount(0);
    }
  });

  // Return the unsubscribe function to clean up the listener when no longer needed
  return unsubscribe;
};

export default countCartItems;
