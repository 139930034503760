import { getAuth, signInAnonymously } from "firebase/auth";
import Cookies from "js-cookie";
import { getFirestore, doc, setDoc } from "firebase/firestore";

// Function to silently create and log in an anonymous user
const createAnonymousUser = async () => {
  const auth = getAuth();
  const existingUserUID = Cookies.get("anonymousUID");

  // If the user is already logged in (anonymous or otherwise), return the user
  if (auth.currentUser && auth.currentUser.uid === existingUserUID) {
    return auth.currentUser;
  }

  const createUserInFirestore = async (user) => {
    const firestore = getFirestore();
    const userDocRef = doc(firestore, "Users/UsersList/DataBase", user.uid);

    // Add the user to Firestore, including metadata from auth creation
    await setDoc(
      userDocRef,
      {
        uid: user.uid,
        isAnonymous: user.isAnonymous, // Store if the user is anonymous
        email: user.email || null, // Email can be null for anonymous users
        metaData: {
          creationTime: user.metadata.creationTime, // Store creation time from auth metadata
          lastSignInTime: user.metadata.lastSignInTime, // Store last sign-in time
        },
        createdAt: new Date(), // Timestamp when added to Firestore
        // You can add additional user fields here
      },
      { merge: true }
    );
  };

  try {
    // Sign in anonymously
    const anonUser = await signInAnonymously(auth);
    const user = anonUser.user;

    // Save the anonymous user UID in a cookie for future use
    Cookies.set("anonymousUID", user.uid, { expires: 365 });

    // Store user data, including metadata in Firestore
    await createUserInFirestore(user);

    console.log("Anonymous user created and logged in:", user.uid);
    return user;
  } catch (error) {
    console.error("Error creating anonymous user:", error);
    throw new Error("Failed to create anonymous user.");
  }
};

export default createAnonymousUser;
