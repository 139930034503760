import React, { useState, useEffect, useContext } from "react";
import { collection, onSnapshot, getDocs, doc, getDoc, setDoc, deleteDoc } from "firebase/firestore";
import HeaderShop from "../../elements/HeaderShop";
import ListItem from "../../elements/ListItem";
import { useFirestore } from "../../firebase/FirestoreContext";
import "../../styles/global.css";
import Cookies from "js-cookie";
import { UserContext } from "../../context/UserContext";
import NewsletterForm from "./NewsletterForm";
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";
import { Helmet } from "react-helmet";
import { logAnalyticsEvent } from "../../firebase/firebaseConfig";

const ShopPage = ({}) => {
  //
  const environment = useContext(EnvContext);

  //
  const db = useFirestore();

  const [loading, setLoading] = useState(true);
  const [recentSearches, setRecentSearches] = useState([]);
  const [contentType, setContentType] = useState("products");
  const [searchResults, setSearchResults] = useState([]);
  //
  const [products, setProducts] = useState([]);
  const [fullProducts, setFullProducts] = useState([]); // Full product list from Firestore
  const [filteredProducts, setFilteredProducts] = useState([]); // Filtered product list
  const [searchTerm, setSearchTerm] = useState("");
  const [shopPageScrollPosition, setShopPageScrollPosition] = useState(() => Cookies.get("ShopPageScrollPosition") || 0);
  const [filters, setFilters] = useState({
    categories: [],
    types: [],
    strain_types: [], // Added strain_types filter
    priceOrder: "",
    inStockOnly: false,
  });

  const { user, loading: userLoading } = useContext(UserContext);
  const userId = user ? user.uid : null;

  //
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  useEffect(() => {
    if (!userLoading && shopPageScrollPosition !== 0) {
      setTimeout(() => {
        window.scrollTo(0, parseFloat(shopPageScrollPosition));
        Cookies.remove(`${collectionPath}ShopPageScrollPosition`);
        setShopPageScrollPosition(0); // Reset scroll position state
      }, 300); // A small delay to allow the page to finish rendering
    }
  }, [userLoading, shopPageScrollPosition]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, `${collectionPath}Listings/ListingsList/DataBase`), (snapshot) => {
      const listings = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })).filter((listing) => listing.listing_price > 0);

      listings.sort((a, b) => {
        //
        if (a.listing_stock_amount === 0 && b.listing_stock_amount !== 0) {
          return 1;
        }
        if (a.listing_stock_amount !== 0 && b.listing_stock_amount === 0) {
          return -1;
        }
        return 0;
      });

      // sort that are first all sooperdeals, then else
      listings.sort((a, b) => {
        // Check if 'a' or 'b' is a sooperdeal product
        const isSooperDealA = a.sooperdeal_start_date && a.sooperdeal_price !== undefined;
        const isSooperDealB = b.sooperdeal_start_date && b.sooperdeal_price !== undefined;

        // Prioritize sooperdeal products
        if (isSooperDealA && !isSooperDealB) return -1;
        if (!isSooperDealA && isSooperDealB) return 1;

        // If both or neither are sooperdeal, compare by start date if available
        if (isSooperDealA && isSooperDealB) {
          return new Date(a.sooperdeal_start_date) - new Date(b.sooperdeal_start_date);
        }

        // If neither are sooperdeal, return 0 (keep the order)
        return 0;
      });

      setProducts(listings);
      setFullProducts(listings);
      setLoading(false);
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [db, environment]);

  const applyFilters = (filters = {}, searchTerm = "") => {
    setLoading(true);

    // Filter by search term, category, type, strain type, and stock
    let filteredListings = fullProducts.filter((product) => {
      const matchesNameOrBrand =
        product.product_name.toLowerCase().includes(searchTerm.toLowerCase()) || product?.brand_name?.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesCategory = !filters.product_category || filters.product_category.length === 0 || filters.product_category.includes(product.product_category);

      console.log("product", JSON.stringify(filters.product_category));

      //const matchesType = !filters.types || filters.types.length === 0 || filters.types.includes(product.product_type);

      const matchesType =
        !filters.product_type || filters.product_type.length === 0 || (product.product_type && filters.product_type.includes(product.product_type));

      const matchesStrainType = !filters.strain_types || filters.strain_types.length === 0 || filters.strain_types.includes(product.strain_type);

      const matchesInStock = !filters.inStockOnly || product.listing_stock_amount > 0 || product.listing_stock_amount === -1;

      return matchesNameOrBrand && matchesCategory && matchesType && matchesStrainType && matchesInStock;
    });

    // Filter by "on sale" items if the sale filter is active
    if (filters.isOnSale) {
      filteredListings = filteredListings.filter((product) => {
        const now = new Date();

        const isSooperDealActive =
          product.sooperdeal_start_date &&
          product.sooperdeal_expiration_date &&
          new Date(product.sooperdeal_start_date) <= now &&
          new Date(product.sooperdeal_expiration_date) >= now;

        const isSaleActive =
          product.listing_sale_startDate &&
          product.listing_sale_endDate &&
          new Date(product.listing_sale_startDate) <= now &&
          new Date(product.listing_sale_endDate) >= now;

        return isSooperDealActive || isSaleActive; // Only return products on sale
      });
    }

    // Now calculate final price for each product
    filteredListings = filteredListings.map((product) => {
      const now = new Date();

      const isSooperDealActive =
        product.sooperdeal_start_date &&
        product.sooperdeal_expiration_date &&
        new Date(product.sooperdeal_start_date) <= now &&
        new Date(product.sooperdeal_expiration_date) >= now;

      const isSaleActive =
        product.listing_sale_startDate &&
        product.listing_sale_endDate &&
        new Date(product.listing_sale_startDate) <= now &&
        new Date(product.listing_sale_endDate) >= now;

      // Calculate final price based on SooperDeal, Sale, or Regular price
      const finalPrice = isSooperDealActive ? product.sooperdeal_price : isSaleActive ? product.listing_sale_price : product.listing_price;

      return {
        ...product,
        finalPrice, // Add the computed final price to each product
      };
    });

    // Sort the filtered items by final price if required
    if (filters.priceOrder === "low-to-high") {
      filteredListings.sort((a, b) => a.finalPrice - b.finalPrice); // Sort by final price ascending
    } else if (filters.priceOrder === "high-to-low") {
      filteredListings.sort((a, b) => b.finalPrice - a.finalPrice); // Sort by final price descending
    }

    // Update the filtered products list
    setProducts(filteredListings);
    setLoading(false);
  };

  const clearFilters = () => {
    // Reset all filters to their default state
    setFilters({
      categories: [],
      types: [],
      strain_types: [], // Added strain_types reset here
      priceOrder: "",
      inStockOnly: false,
    });
    resetProductsToFullList();
  };

  const resetProductsToFullList = async () => {
    setContentType("products");

    setProducts(fullProducts); // Assuming you have a state for products
  };

  const fetchRecentSearches = async () => {
    if (userId) {
      const searchHistoryRef = collection(db, `Users/UsersList/DataBase/${userId}/SearchHistory`);
      const searchHistorySnapshot = await getDocs(searchHistoryRef);
      // list with doc id and data
      const searches = searchHistorySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRecentSearches(searches);
    }
  };

  const deleteFromHistory = async (searchID) => {
    if (userId) {
      try {
        // Create a document reference for the specific search entry
        const searchHistoryRef = doc(db, `Users/UsersList/DataBase/${userId}/SearchHistory/${searchID}`);

        // Delete the document from Firestore
        await deleteDoc(searchHistoryRef);
        // fetch new recent searches
        fetchRecentSearches();
      } catch (error) {
        console.error("Error deleting search history item:", error);
      }
    }
  };

  // useEffects and call fetchRecentSearches when contentType is recentSearches
  useEffect(() => {
    if (contentType === "recentSearches" && user?.uid) {
      fetchRecentSearches();
    }
  }, [contentType]);

  // analytics
  useEffect(() => {
    logAnalyticsEvent("shop_view", {
      page_title: environment == "staging" ? "STAGING - Shop Page" : "Shop Page",
      page_location: window.location.href,
      page_path: window.location.pathname,
      environment: environment,
    });
  }, []);

  const toggleContent = (type) => {
    setContentType(type); // This updates the contentType

    if (type === "recentSearches") {
      console.log("Fetching recent searches"); // Add a log here to verify
      fetchRecentSearches(); // Call fetchRecentSearches when contentType is recentSearches
    }
  };

  const handleSearch = async (searchTerm) => {
    setLoading(true);

    const listingsRef = collection(db, `${collectionPath}Listings/ListingsList/DataBase`);
    const searchSnapshot = await getDocs(listingsRef);

    const searchResults = searchSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })).filter((listing) => listing.listing_stock_amount !== 0);
    // sort by date

    setSearchResults(searchResults);
    setContentType("searchResults");
    setLoading(false);
  };

  // Show loading screen until user and age gate checks are complete
  if (userLoading || loading) {
    return <div className="loading">Loading...</div>;
  }

  //
  const handleSearchTerm = (term) => {
    setSearchTerm(term);

    if (term === "") {
      // If the search term is exactly an empty string, show all products
      console.log("Empty search term, showing all products");
      setProducts(fullProducts); // Show all items when the search term is cleared
    } else {
      // If there's a search term, apply filters based on the search term
      console.log("Applying filters with term:", term);
      applyFilters({}, term);
    }
  };

  const handleSearchTermClick = (term) => {
    setSearchTerm(term); // Set the clicked search term to state
    applyFilters({}, term);
    setContentType("products");
  };

  //

  return (
    <div className="shop-page">
      <div>
        <Helmet>
          <title>{environment == "staging" ? "STAGING - " : ""}SooperStock</title>
          <meta
            name="description"
            content="The specialty goods superstore — incredible quality, curated selection, and fast delivery all at unbeatable prices. Satisfaction guaranteed or your money back."
          />
          <meta property="og:title" content="SooperStock" />
          <meta
            property="og:description"
            content="The specialty goods superstore — incredible quality, curated selection, and fast delivery all at unbeatable prices. Satisfaction guaranteed or your money back."
          />
          <meta property="og:image" content="" />
          <meta property="og:url" content={`https://sooperstock.com/images/sooperstock_logo.svg`} />
          <meta property="og:type" content="product" />
        </Helmet>
      </div>
      {Cookies.get("ageGate") === "true" && (
        <>
          <HeaderShop
            handleSearchTerm={handleSearchTerm}
            handleSearchTermClick={handleSearchTermClick}
            fetchRecentSearches={fetchRecentSearches}
            toggleContent={toggleContent}
            handleSearch={handleSearch}
            applyFilters={applyFilters}
            clearFilters={clearFilters}
            setContentType={setContentType} // Pass setContentType to HeaderShop
            contentType={contentType} // Pass contentType to HeaderShop
          />
          <NewsletterForm />

          {contentType === "recentSearches" && (
            <div style={styles.recentSearchesContainer}>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    alignContent: "flex-start",
                    justifyContent: "flex-start",
                    alignSelf: "flex-start",
                  }}
                >
                  <p
                    style={{
                      paddingBottom: 20,
                      padding: "10px 10px 10px 0",
                      marginBottom: 20,
                      cursor: "pointer",
                      textDecoration: "none",
                      fontSize: 13,
                      opacity: 0.7,
                      display: "flex",
                    }}
                    // switch to products
                    onClick={() => setContentType("products")}
                  >
                    &#8249; <span style={{ marginTop: 1, marginLeft: 6 }}>Back</span>
                  </p>
                </div>
              </div>
              <h4 style={{ marginBottom: "" }}>Recent Searches</h4>
              <ul>
                {recentSearches.length > 0 ? (
                  recentSearches.map((search, index) => (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "8px 0",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => handleSearchTermClick(search.searchTerm)}
                      >
                        {" "}
                        {search?.searchTerm}
                      </div>
                      <div onClick={() => deleteFromHistory(search.id)} style={{ fontSize: 20, cursor: "pointer" }}>
                        &#215;
                      </div>
                    </li>
                  ))
                ) : (
                  <p style={{ padding: "20px 0", opacity: 0.5 }}>No recent searches</p>
                )}
              </ul>
            </div>
          )}
          {contentType === "searchResults" && (
            <div style={styles.productGrid}>
              {products.length > 0 ? (
                products.map((product, index) => <ListItem key={index} product={product} index={index} dataMultiple={products} />)
              ) : (
                <div
                  style={{
                    width: "100vw",
                    textAlign: "center",
                    padding: "50px 0",
                  }}
                >
                  No results found
                </div>
              )}
            </div>
          )}
          {contentType === "products" && (
            <div style={styles.productGrid}>
              {products.length > 0 ? (
                products.map((product, index) => <ListItem key={index} product={product} index={index} dataMultiple={products} />)
              ) : (
                <div
                  style={{
                    width: "100vw",
                    textAlign: "center",
                    padding: "50px 0",
                  }}
                >
                  No results found
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const styles = {
  productGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
    justifyContent: "start",
    boxSizing: "border-box",
  },
  recentSearchesContainer: {
    marginBottom: "20px",
    padding: "10px",
    borderRadius: "5px",
  },
};

export default ShopPage;
