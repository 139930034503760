// src/pages/PaymentSuccess.js
import React, { useEffect, useState, useContext } from "react";
import Header from "../../elements/Header";
import { useNavigate, useLocation } from "react-router-dom";
import { doc, getDoc, query, where, getDocs, collection } from "firebase/firestore"; // Import Firestore functions
import { useFirestore } from "../../firebase/FirestoreContext"; // Import your Firestore context
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";

const PaymentSuccess = () => {
  //
  const environment = useContext(EnvContext);
  //
  const location = useLocation();
  const navigate = useNavigate();
  const firestore = useFirestore(); // Get Firestore instance from context

  const { cartID, items, cart_sooperstock_ID } = location.state || {};
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentCartID, setCurrentCartID] = useState(cart_sooperstock_ID); // Set initial cart_sooperstock_ID

  // environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  // Extract session_id from URL parameters
  const sessionId = new URLSearchParams(location.search).get("session_id");
  const cartId = new URLSearchParams(location.search).get("cart_id");

  const handleContinueShopping = () => {
    navigate("/"); // Adjust the path to your shop page
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        let orderData = null;

        // Handle sessionId-based fetching
        if (sessionId) {
          const cartQuery = query(collection(firestore, `${collectionPath}Carts/CartsList/DataBase`), where("stripeSessionId", "==", sessionId));
          const cartSnapshot = await getDocs(cartQuery);

          if (!cartSnapshot.empty) {
            const cartDoc = cartSnapshot.docs[0];
            orderData = cartDoc.data();
            orderData.cartID = cartDoc.id; // Add the document ID to the data
          } else {
            console.error("No cart found matching the provided sessionId.");
            setError("Order not found for the given session ID.");
            return;
          }
        }
        // Handle cartId-based fetching
        else if (cartId) {
          const cartDocRef = doc(firestore, `${collectionPath}Carts/CartsList/DataBase`, cartId);
          const cartDocSnapshot = await getDoc(cartDocRef);

          if (cartDocSnapshot.exists()) {
            orderData = cartDocSnapshot.data();
            orderData.cartID = cartDocSnapshot.id; // Add the document ID to the data
          } else {
            console.error("No cart found matching the provided cartId.");
            setError("Order not found for the given cart ID.");
            return;
          }
        } else {
          throw new Error("Neither sessionId nor cartId provided.");
        }

        // Fetch items from the sub-collection
        if (orderData && orderData.cartID) {
          const itemsQuery = query(collection(firestore, `${collectionPath}Carts/CartsList/DataBase/${orderData.cartID}/Items`));
          const itemsSnapshot = await getDocs(itemsQuery);

          // Map the items from the sub-collection
          const itemsArray = itemsSnapshot.docs.map((item) => ({
            id: item.id, // Document ID for the item
            ...item.data(),
          }));

          setCurrentCartID(orderData?.cart_sooperstock_ID); // Set the current cart ID

          orderData.items = itemsArray;
        } else {
          console.error("Order data is missing cartID.");
          setError("Invalid order data. Could not fetch items.");
          return;
        }

        // Apply discount logic (if applicable)
        const discountedItems = orderData.items.map((item) => {
          const itemTotalPrice = item.final_price * item.quantity;
          const discountedPrice = orderData.discountApplied ? itemTotalPrice * (1 - orderData.discountApplied / 100) : itemTotalPrice;

          return {
            ...item,
            discountedPrice, // Add discounted price per item
          };
        });

        const totalItemsPrice = discountedItems.reduce((total, item) => total + (item.discountedPrice ?? item.final_price), 0);
        const totalPrice = (totalItemsPrice + (orderData.shippingCost || 0)).toFixed(2);

        // Update order details with discounted items and total price
        setOrderDetails({
          ...orderData,
          items: discountedItems,
          totalPrice,
        });
      } catch (err) {
        console.error("Error fetching order details:", err);
        setError("Error fetching order details.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [sessionId, cartId, firestore, collectionPath]);

  if (loading) {
    return <div>Loading...</div>; // Loading state
  }

  if (error) {
    return <div>{error}</div>; // Display error message
  }

  // Use orderDetails for rendering
  const { items: orderedItems, shippingCost = 0 } = orderDetails;

  // if shippingCost is not provided, set it to 0
  //shippingCost = shippingCost || 0;

  return (
    <div style={styles.container}>
      <Header />
      <div style={styles.content}>
        <div style={styles.animationContainer}>
          <dotlottie-player
            src="https://lottie.host/c86b1f88-9cf7-474c-8075-512aeecf1874/EQZ94rIPgj.json"
            background="transparent"
            speed="1"
            autoplay
            style={{ height: "220px", width: "220px" }}
          ></dotlottie-player>
        </div>
        <h2 style={styles.title}>Thank you for your purchase!</h2>
        <p style={styles.orderNumber}>Order Number: {currentCartID}</p> {/* Displaying the currentCartID */}
        <h3 style={styles.subtitle}>Items Ordered:</h3>
        <ul style={styles.itemsList}>
          {orderedItems &&
            orderedItems.map((item, index) => (
              <li key={index} style={styles.item}>
                <div style={styles.itemDetails}>
                  <img
                    src={item?.images && item?.images?.length > 0 ? item?.images[0] : "/images/no_product_image.png"}
                    alt={item.product_name}
                    style={styles.itemImage}
                  />
                  <div>
                    <p style={styles.itemName}>{item.product_name}</p>
                    <p style={{ ...styles.itemQuantity, textAlign: "left" }}>Qty: {item.quantity}</p>
                  </div>
                </div>
                <p style={styles.itemPrice}>${(item.discountedPrice ?? item.final_price * item.quantity).toFixed(2)}</p>
              </li>
            ))}

          {shippingCost > 0 && (
            <li style={styles.item}>
              <div style={styles.itemDetails}>
                <div>
                  <p
                    style={{
                      ...styles.itemName,
                      padding: 0,
                      textAlign: "left",
                    }}
                  >
                    Shipping
                  </p>
                </div>
              </div>
              <p style={styles.itemPrice}>${parseFloat(shippingCost).toFixed(2)}</p>
            </li>
          )}
          <li style={styles.item}>
            <div style={styles.itemDetails}>
              <div>
                <p style={{ ...styles.itemName, padding: 0, textAlign: "left" }}>Total</p>
              </div>
            </div>
            <p style={styles.itemPrice}>${orderDetails.totalPrice}</p>
          </li>
        </ul>
        <button style={styles.continueButton} onClick={handleContinueShopping}>
          Continue Shopping
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#fff",
    minHeight: "100vh",
  },
  content: {
    padding: "20px",
    maxWidth: "600px",
    margin: "0 auto",
    textAlign: "center",
    fontFamily: "'Inter', sans-serif",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  animationContainer: {
    marginBottom: "20px",
    alignSelf: "center",
  },
  title: {
    fontSize: "24px",
    marginBottom: "10px",
  },
  orderNumber: {
    fontSize: "16px",
    color: "gray",
    marginBottom: "20px",
  },
  subtitle: {
    fontSize: "18px",
    marginBottom: "10px",
    textAlign: "left",
  },
  itemsList: {
    listStyleType: "none",
    padding: "0",
    marginBottom: "20px",
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "15px",
    borderBottom: "1px solid #ddd",
    paddingBottom: "10px",
  },
  itemDetails: {
    display: "flex",
    alignItems: "center",
  },
  itemImage: {
    width: "50px",
    height: "50px",
    marginRight: "15px",
    objectFit: "cover",
    borderRadius: "5px",
  },
  itemName: {
    fontSize: "16px",
    marginBottom: "5px",
  },
  itemQuantity: {
    fontSize: "14px",
    color: "gray",
  },
  itemPrice: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  continueButton: {
    padding: "15px",
    backgroundColor: "black",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    width: "100%",
  },
};

export default PaymentSuccess;
