import React, { useState, useEffect, useContext } from "react";
import Header from "../../elements/Header";
import { EnvContext } from "../../context/EnvContext";
import { logAnalyticsEvent } from "../../firebase/firebaseConfig";

const FAQ = ({ isDevMode }) => {
  // analytics on load
  const environment = useContext(EnvContext);
  useEffect(() => {
    logAnalyticsEvent("FAQ_view", {
      page_title: environment == "staging" ? "STAGING - FAQ" : "FAQ",
      page_location: window.location.href,
      page_path: window.location.pathname,
      environment: environment,
    });
  }, []);

  return (
    <div>
      <Header title="FAQ" />
      <div style={{ padding: "20px" }}>
        <div>
          <br />
          <b>How are you able to provide such low prices?</b>
          <br />
          Our mission is to bring high quality products to you at prices that cannot be beat. We do this by leveraging our collective buying power to drive
          discounts as well as gain access to overstock inventory. Then, we pass the savings on to you!
          <br /> <br />
          <b>Is Sooperstock compliant?</b>
          <br />
          All of the products listed on Sooperstock undergo audit and review processes to ensure compliance with federal, state and local regulations.
          <br /> <br />
          <b>Is there a maximum order size?</b>
          <br />
          Any limits on the total quantity of a given product you can order will be noted on the product description page and/or in your cart.
          <br /> <br />
          <b>Is there a minimum order size?</b>
          <br />
          There is no minimum order size. However, please check your cart for order thresholds to receive free shipping.
          <br /> <br />
          <b>What type of packaging do you use for shipping?</b>
          <br />
          All products are shipped in standard unbranded boxes.
          <br /> <br />
          <b>When can I expect to receive my product?</b>
          <br />
          All orders are typically processed and shipped out within 3 business days. Any delays to this will be communicated via email. Once shipped you can
          expect to receive your order in two to three business days from the shipment date.
          <br /> <br />
          <b>How will I know when my order is shipped?</b>
          <br />
          You will receive an email when your order is on its way. If you have not received your order within 7 business days, feel free to contact us at
          support@sooperstock.com. Please reference your order number when reaching out and one of our representatives will be happy to assist you.
          <br /> <br />
          <b>How do Sooper Deals work?</b>
          <br />
          We work hard to bring you the best brands and best products at the best prices. These products are heavily discounted down to or even below wholesale
          price. But these deals are extremely limited and they expire in a matter of hours or days, so act fast!
          <br /> <br />
          <b>What should I do if I receive a faulty product?</b>
          <br />
          Reach out to support@sooperstock.com and we’ll make sure to take care of you. This is a part of our customer guarantee and overall commitment to your
          satisfaction.
          <br /> <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default FAQ;
