import { getFirestore, collection, addDoc } from "firebase/firestore";

// Function to handle referral entries for both referee and referrer using a loop for dynamic conditions
const createReferral = async ({ collectionPath, nestedReferralData, referrerUid, refereeUid }) => {
  const firestore = getFirestore();
  const currentDate = new Date();

  console.log("Processing referral data:", JSON.stringify(nestedReferralData));

  const actions = [
    {
      userType: "referee",
      trigger: nestedReferralData?.refereeTrigger,
      amount: nestedReferralData?.refereeAmount,
      uid: refereeUid,
      description: "Reward for referee action",
      collectionPath: `${collectionPath}Wallets/UserWallets/DataBase`,
      isWalletEntry: true,
    },
    {
      userType: "referrer",
      trigger: nestedReferralData?.referralTrigger,
      amount: nestedReferralData?.referralAmount,
      uid: referrerUid,
      description: "Referral reward for referrer",
      collectionPath: `${collectionPath}Referral/ReferralList/DataBase`,
      isWalletEntry: false,
    },
  ];

  try {
    for (const action of actions) {
      console.log(`Processing ${action.userType} entry with data:`, action);

      if (action.uid && action.trigger && action.amount) {
        if (action.isWalletEntry) {
          const walletRef = collection(firestore, action.collectionPath);
          await addDoc(walletRef, {
            wallet_value: action.amount,
            date_created: currentDate,
            user_uid: action.uid,
            description: action.description,
            givenBy: referrerUid,
          });
          console.log(`Wallet entry created for ${action.userType} (user ID: ${action.uid}) with amount: ${action.amount}`);
        } else {
          const referralRef = collection(firestore, action.collectionPath);
          await addDoc(referralRef, {
            trigger_user_uid: refereeUid,
            trigger_action_type: action.trigger,
            trigger_user_being_rewarded_uid: referrerUid,
            trigger_user_being_rewarded_amount: action.amount,
            referral_name: nestedReferralData?.name,
            referrer_award: nestedReferralData?.referralAmount,
            referrer_uid: referrerUid,
            referee_award: nestedReferralData?.refereeAmount,
            referee_uid: refereeUid,
            action_type: action.trigger,
            createdAt: currentDate,
            status: "pending",
            date_created: currentDate,
          });
          console.log(`Referral entry created for ${action.userType} (user ID: ${action.uid}), awaiting referee action.`);
        }
      } else {
        console.log(`Skipped ${action.userType} entry due to missing data:`, {
          uidMissing: !action.uid,
          triggerMissing: !action.trigger,
          amountMissing: !action.amount,
        });
      }
    }
  } catch (error) {
    console.error("Error creating referral and wallet entries:", error);
  }
};

export default createReferral;
