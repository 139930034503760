import React, { createContext, useEffect } from "react";

// Create a context
export const EnvContext = createContext();

const EnvProvider = ({ children }) => {
  // Get environment from process.env
  const environment = process.env.REACT_APP_ENV || "production";

  const title = process.env.REACT_APP_TITLE || "Sooperstock";

  // Set the document title dynamically
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <EnvContext.Provider value={environment}>{children}</EnvContext.Provider>
  );
};

export default EnvProvider;
