const config = {
  apiConfig: {
    //API_ENDPOINT_URL: "http://127.0.0.1:5001/sooperstock-app/us-central1/api",
    API_ENDPOINT_URL: "https://us-central1-sooperstock-app.cloudfunctions.net/api",
  },

  // enveironment: "staging" | "production"
  enveironment: {
    production: {
      collectionPath: "",
    },
    staging: {
      collectionPath: "_DEVELOPMENT/stageapi/",
    },
  },
};

export default config;
