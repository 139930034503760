import React, { useState, useEffect, useContext } from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  OAuthProvider,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "./SignIn.css";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { nanoid } from "nanoid";
import { EnvContext } from "../../context/EnvContext";
import { logAnalyticsEvent } from "../../firebase/firebaseConfig";

const SignIn = () => {
  const environment = useContext(EnvContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [user, setUser] = useState(null);

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setError("");
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  // Analytics
  useEffect(() => {
    // analytics
    logAnalyticsEvent("sign_in_view", {
      page_title: environment == "staging" ? "STAGING - Sign In Page" : "Sign In Page",
      page_location: window.location.href,
      page_path: window.location.pathname,
      environment: environment,
    });
  }, []);

  const createUserInFirestore = async (user) => {
    const firestore = getFirestore();
    const userDocRef = doc(firestore, "Users/UsersList/DataBase", user.uid);

    await setDoc(
      userDocRef,
      {
        uid: user.uid,
        email: user.email,
        isAnonymous: false,
        createdAt: new Date(),
        user_code: nanoid(6),
      },
      { merge: true }
    );
  };

  const handleSignIn = async (event) => {
    event.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user);
      setError("");
      navigate(`/`);
    } catch (error) {
      setError(error.message);
    }
    // analytics
    logAnalyticsEvent("sign_in_mail", {
      method: "email",
      environment: environment,
    });
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      await createUserInFirestore(result.user);
      navigate(`/`);
    } catch (error) {
      setError(error.message);
    }
    // analytics
    logAnalyticsEvent("sign_in_google", {
      method: "google",
      environment: environment,
    });
  };

  const handleAppleSignIn = async () => {
    const provider = new OAuthProvider("apple.com");
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      await createUserInFirestore(result.user);
      navigate(`/`);
    } catch (error) {
      setError(error.message);
    }
    // analytics
    logAnalyticsEvent("sign_in_apple", {
      method: "apple",
      environment: environment,
    });
  };

  return (
    <div className="signin-container">
      <a href="/" style={{ cursor: "pointer" }}>
        <img src="/images/sooperstock_logo.svg" alt="Sooperstock" style={{ height: "47px", marginBottom: 10 }} />
      </a>
      <p style={{ marginBottom: 50, fontSize: 12, textTransform: "uppercase" }}>The specialty goods superstore</p>

      <form className="signin-form" onSubmit={handleSignIn}>
        <label>Sign in with email</label>
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <button type="submit" className="btn btn-primary">
          Log in
        </button>
      </form>

      <button className="btn btn-secondary" onClick={handleGoogleSignIn}>
        <img src="images/google_icon.png" style={{ height: "12px", marginRight: "3px" }} /> Continue With Google
      </button>

      <button className="btn btn-primary btn-apple" onClick={handleAppleSignIn}>
        <span role="img" aria-label="apple">
          
        </span>{" "}
        Sign in with Apple
      </button>

      {error && <p className="error">{error}</p>}

      <p className="footer-text">
        Forgot your password? <a href="/forgotten-pass">Click here</a> or <a href="/signup">Sign Up</a>
      </p>

      <p className="footer-text">
        By creating an account, you agree to Sooperstock's <a href="/terms">terms of service</a>.
      </p>

      <p className="footer-text" onClick={() => navigate(`/`)} style={{ cursor: "pointer" }}>
        Go to <u>HomePage</u>.
      </p>
    </div>
  );
};

export default SignIn;
