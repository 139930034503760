import React, { useState, useEffect, useContext } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithPopup,
  onAuthStateChanged,
  OAuthProvider,
  GoogleAuthProvider,
  linkWithCredential,
  EmailAuthProvider,
  linkWithPopup,
  sendEmailVerification,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "./SignIn.css"; // Import a CSS file for styling
import { getFirestore, doc, setDoc, getDocs, query, collection, where } from "firebase/firestore";
import { nanoid } from "nanoid";
import { Snackbar, Alert, Button } from "@mui/material";
import Cookies from "js-cookie";
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";
//
import createReferral from "../../functions/createReferral";
import { logAnalyticsEvent } from "../../firebase/firebaseConfig";

const SignUp = () => {
  const environment = useContext(EnvContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState(""); // State for confirming email
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [user, setUser] = useState(null); // State to hold user info
  const [openSnackbar, setOpenSnackbar] = useState(false); // Alert visibility

  const auth = getAuth();

  //
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user); // Update the state with the signed-in user's info
        setError(""); // Clear any error messages
      } else {
        setUser(null); // Reset user info if not logged in
      }
    });

    return () => unsubscribe(); // Clean up the subscription
  }, [auth]);

  // Analytics
  useEffect(() => {
    logAnalyticsEvent("sign_up_view", {
      page_title: environment == "staging" ? "STAGING - Sign Up Page" : "Sign Up Page",
      page_location: window.location.href,
      page_path: window.location.pathname,
      environment: environment,
    });
  }, []);

  const createUserInFirestore = async (user) => {
    const firestore = getFirestore();
    const userDocRef = doc(firestore, "Users/UsersList/DataBase", user.uid);

    // Add the user to Firestore
    await setDoc(
      userDocRef,
      {
        uid: user.uid,
        email: user.email,
        isAnonymous: false, // Make sure isAnonymous is set to false after linking or signing up
        createdAt: new Date(),
        user_code: nanoid(6), // Generate a random 6-character user code
      },
      { merge: true }
    );
  };

  const linkAnonymousAccount = async (provider) => {
    try {
      // If the user is signing up with email and password
      if (provider === "email") {
        const credential = EmailAuthProvider.credential(email, password);
        const linkedUser = await linkWithCredential(auth.currentUser, credential);
        return linkedUser.user;
      }
      // If the user is using a third-party provider like Google or Apple
      else {
        const linkedUser = await linkWithPopup(auth.currentUser, provider);
        return linkedUser.user;
      }
    } catch (error) {
      throw new Error("Error linking anonymous account: " + error.message);
    }
  };

  const handleSignUp = async (event) => {
    event.preventDefault();

    // Check if emails match
    if (email !== confirmEmail) {
      setError("Emails do not match.");
      return;
    }

    /* Referral program - for referral_link */
    let referrerUid = null;
    const referralData = await checkReferralCriteria();

    const nestedReferralData = referralData?.referralData;

    if (nestedReferralData) {
      referrerUid = nestedReferralData.referrerId || null; // Update referrerUid instead of redeclaring
    } else {
    }

    if (auth.currentUser && auth.currentUser.isAnonymous) {
      try {
        const linkedUser = await linkAnonymousAccount("email"); // Link Email account
        setUser(linkedUser);
        setError("");
        await createUserInFirestore(linkedUser); // Update Firestore and set isAnonymous to false

        // Access the nested referral data object

        if (referralData && referrerUid) {
          await createReferral({
            collectionPath,
            nestedReferralData,
            referrerUid,
            refereeUid: linkedUser.uid,
          });
        }

        /* end of referral program */

        // Send email verification
        await sendEmailVerification(linkedUser);
        setOpenSnackbar(true);

        navigate(`/`);
      } catch (error) {
        setError(error.message);
      }
      logAnalyticsEvent("sign_up_anonymous", {
        method: "anonymous",
        environment: environment,
      });
    } else {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          setUser(user);
          setError("");

          await createUserInFirestore(user);

          /* Referral program - for referral_link */

          if (referralData && referrerUid) {
            await createReferral({
              collectionPath,
              nestedReferralData,
              referrerUid,
              refereeUid: user.uid,
            });
          }

          /* end of referral program */

          // Send email verification
          await sendEmailVerification(user);
          setOpenSnackbar(true);

          navigate(`/`);
        })
        .catch((error) => {
          setError(error.message);
        });
      logAnalyticsEvent("sign_up_email", {
        method: "email",
        environment: environment,
      });
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();

    /* Referral program - for referral_link */
    let referrerUid = null;
    const referralData = await checkReferralCriteria();

    // Access the nested referral data object
    const nestedReferralData = referralData?.referralData;

    if (nestedReferralData) {
      referrerUid = nestedReferralData.referrerId || null; // Update referrerUid instead of redeclaring
    } else {
    }

    /* end of referral program */

    if (auth.currentUser && auth.currentUser.isAnonymous) {
      try {
        const linkedUser = await linkAnonymousAccount(provider); // Link Google account
        setUser(linkedUser);
        await createUserInFirestore(linkedUser);

        /* Referral program - for referral_link */
        if (nestedReferralData && referrerUid) {
          await createReferral({
            collectionPath,
            nestedReferralData,
            referrerUid,
            refereeUid: linkedUser.uid,
          });
        }

        navigate(`/`);
      } catch (error) {
        setError(error.message);
      }
    } else {
      signInWithPopup(auth, provider)
        .then(async (result) => {
          setUser(result.user);
          await createUserInFirestore(result.user);

          /* Referral program - for referral_link */
          if (nestedReferralData && referrerUid) {
            await createReferral({
              collectionPath,
              nestedReferralData,
              referrerUid,
              refereeUid: result.user.uid,
            });
          }

          /* end of referral program */
          navigate(`/`);
        })
        .catch((error) => {
          setError(error.message);
        });
    }

    logAnalyticsEvent("sign_up_google", {
      method: "google",
      environment: environment,
    });
  };

  const handleAppleSignIn = async () => {
    const provider = new OAuthProvider("apple.com");

    /* Referral program - for referral_link */
    const referralData = await checkReferralCriteria();
    let referrerUid = null;

    // Access the nested referral data object
    const nestedReferralData = referralData?.referralData;

    if (nestedReferralData) {
      referrerUid = nestedReferralData.referrerId || null; // Update referrerUid instead of redeclaring
    } else {
    }

    if (auth.currentUser && auth.currentUser.isAnonymous) {
      try {
        const linkedUser = await linkAnonymousAccount(provider); // Link Apple account
        setUser(linkedUser);
        await createUserInFirestore(linkedUser);

        /* Referral program - for referral_link */
        if (referralData && referrerUid) {
          await createReferral({
            collectionPath,
            nestedReferralData,
            referrerUid,
            refereeUid: linkedUser.uid,
          });
        }
        /* end of referral program */
        navigate(`/`);
      } catch (error) {
        setError(error.message);
      }
    } else {
      signInWithPopup(auth, provider)
        .then(async (result) => {
          setUser(result.user);
          await createUserInFirestore(result.user);

          /* Referral program - for referral_link */
          if (referralData && referrerUid) {
            await createReferral({
              collectionPath,
              nestedReferralData,
              referrerUid,
              refereeUid: result.user.uid,
            });
          }
          /* end of referral program */
          navigate(`/`);
        })
        .catch((error) => {
          setError(error.message);
        });
    }
    logAnalyticsEvent("sign_up_apple", {
      method: "apple",
      environment: environment,
    });
  };

  // Referral program - for referral_link
  const checkReferralCriteria = async () => {
    console.log("checkReferralCriteria");

    // Retrieve referral code from cookies
    const referralCode = Cookies.get("userInviteCode");
    console.log("referralCode", referralCode);
    if (!referralCode) {
      console.log("No referral code found in cookies");
      return null; // If no referral code, return null
    }

    // Ensure collectionPath is defined
    if (!collectionPath) {
      console.error("Collection path is undefined");
      return null;
    }
    console.log("collectionPath", collectionPath);

    try {
      const firestore = getFirestore();

      // Query to find user by user_code
      const referralUserQuery = query(collection(firestore, "Users/UsersList/DataBase"), where("user_code", "==", referralCode));
      const referralUserSnapshot = await getDocs(referralUserQuery);

      if (!referralUserSnapshot.empty) {
        const referralUserDoc = referralUserSnapshot.docs[0];
        const referralUserData = referralUserDoc.data();

        console.log("Referral User Data:", referralUserData);

        // Query for active referral based on referralCode
        const referralQuery = query(
          collection(firestore, `${collectionPath}Marketing/Referrals/DataBase`),
          where("name", "==", "referral_link"),
          where("active", "==", true)
        );

        const querySnapshot = await getDocs(referralQuery);

        if (!querySnapshot.empty) {
          const referralData = {
            inviteCode: referralCode,
            referrerId: referralUserDoc.id,
            referrerEmail: referralUserData.email,
            ...querySnapshot.docs[0].data(),
          };

          return { referralData };
        } else {
          console.log("No active referral found");
          return null;
        }
      } else {
        console.log("No user found with the provided referral code");
        return null;
      }
    } catch (error) {
      console.error("Error fetching referral data:", error);
      return null;
    }
  };

  return (
    <div className="signin-container">
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          action={
            <Button color="inherit" size="small" onClick={() => navigate(`/`)}>
              Shop Now
            </Button>
          }
        >
          You’re signed up! Please check your email to verify your account.
        </Alert>
      </Snackbar>

      <div onClick={() => navigate(`/`)} style={{ cursor: "pointer" }}>
        <img src="/images/sooperstock_logo.svg" alt="Sooperstock" style={{ height: "47px", marginBottom: 10 }} />
      </div>
      <p
        style={{
          marginBottom: 20,
          fontSize: 12,
          textTransform: "uppercase",
          marginBottom: 50,
        }}
      >
        The specialty goods superstore
      </p>

      <form className="signin-form" onSubmit={handleSignUp}>
        <label>Sign up with email</label>
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        <input
          type="email"
          placeholder="Confirm Email"
          value={confirmEmail}
          onChange={(e) => setConfirmEmail(e.target.value)}
          required
          onPaste={(e) => e.preventDefault()} // Disable paste to avoid copy-pasting
        />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        <button type="submit" className="btn btn-primary">
          Continue with email
        </button>
      </form>

      <button className="btn btn-secondary" onClick={handleGoogleSignIn}>
        <img
          src="images/google_icon.png"
          style={{
            height: "12px",
            top: "0px",
            marginRight: "3px",
            position: "relative",
          }}
        />{" "}
        Continue With Google
      </button>

      <button className="btn btn-primary btn-apple" onClick={handleAppleSignIn}>
        <span role="img" aria-label="apple">
          
        </span>{" "}
        Sign in with Apple
      </button>

      {error && <p className="error">{error}</p>}

      <p className="footer-text">
        Already have an account? <a href="/signin">Log In</a>
      </p>

      <p className="footer-text">
        By creating an account, you agree to Sooperstock's <a href="/terms">terms of service</a>.
      </p>
      <p className="footer-text" onClick={() => navigate(`/`)} style={{ cursor: "pointer" }}>
        Go to <u>HomePage</u>.
      </p>
    </div>
  );
};

export default SignUp;
