import React, { useState, useEffect, useContext } from "react";
import { auth, db } from "../../firebase/firebaseConfig";
import { onSnapshot, doc, updateDoc } from "firebase/firestore";
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";
import Header from "../../elements/Header";
import { EnvContext } from "../../context/EnvContext";

const UserAccount = () => {
  //
  const environment = useContext(EnvContext);
  //
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Listen for changes in authentication state
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const userId = user.uid; // Get the authenticated user's ID
        const userDocRef = doc(db, "Users/UsersList/DataBase", userId); // Reference to the user document in Firestore

        const unsubscribeSnapshot = onSnapshot(
          userDocRef,
          (doc) => {
            if (doc.exists()) {
              const userData = doc.data();
              setUsername(userData.username || "");
              setEmail(userData.email || "");
            } else {
              console.error("No such document!");
            }
            setLoading(false);
          },
          (error) => {
            console.error("Error fetching user data: ", error);
            setLoading(false);
          }
        );

        // Clean up the Firestore listener when the component unmounts
        return () => unsubscribeSnapshot();
      } else {
        setLoading(false); // No authenticated user
      }
    });

    // Clean up the Auth listener when the component unmounts
    return () => unsubscribeAuth();
  }, []);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  const handleSaveChanges = async () => {
    const user = auth.currentUser; // Get the current authenticated user

    if (!user) {
      alert("No user is signed in.");
      return;
    }

    const userId = user.uid; // Get the authenticated user's ID
    const userDocRef = doc(db, "Users/UsersList/DataBase", userId); // Reference to the user document in Firestore

    try {
      await updateDoc(userDocRef, { username });
      alert("Username updated successfully");
    } catch (error) {
      console.error("Error updating username: ", error);
      alert("Failed to update username");
    }
  };

  const handleChangePassword = async () => {
    if (!oldPassword || !newPassword || !confirmNewPassword) {
      alert("Please fill in all password fields.");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      alert("New passwords do not match.");
      return;
    }

    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, oldPassword);

    try {
      // Reauthenticate user with old password
      await reauthenticateWithCredential(user, credential);
      // If reauthentication is successful, update the password
      await updatePassword(user, newPassword);
      alert("Password updated successfully.");
      // Clear password fields
      setOldPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        alert("Old password is incorrect.");
      } else if (error.code === "auth/weak-password") {
        alert("New password is too weak.");
      } else {
        console.error("Error changing password: ", error);
        alert("Failed to change password.");
      }
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>; // Show a loading indicator
  }

  return (
    <div style={{ ...styles.container }}>
      <Header />
      {/* Content */}
      <div style={styles.content}>
        <div style={styles.inputGroup}>
          <div style={styles.emailDisplay}>
            <label style={styles.label}>Email</label>
            <input type="text" id="email" value={email} disabled style={styles.input} />
          </div>
          <label htmlFor="username" style={styles.label}>
            Username
          </label>
        </div>

        <div style={{ padding: "15px 0 10px 0" }}>
          <label>Change Password</label>
        </div>
        <div style={styles.inputGroup}>
          <label htmlFor="oldPassword" style={styles.label}>
            Current Password
          </label>
          <input type="password" id="oldPassword" value={oldPassword} onChange={handleOldPasswordChange} style={styles.input} />

          <label htmlFor="newPassword" style={styles.label}>
            New Password
          </label>
          <input type="password" id="newPassword" value={newPassword} onChange={handleNewPasswordChange} style={styles.input} />

          <label htmlFor="confirmNewPassword" style={styles.label}>
            Confirm New Password
          </label>
          <input type="password" id="confirmNewPassword" value={confirmNewPassword} onChange={handleConfirmNewPasswordChange} style={styles.input} />
        </div>

        <button style={styles.saveButton} onClick={handleChangePassword}>
          Change Password
        </button>
      </div>
    </div>
  );
};

// Inline styles specific to this page
const styles = {
  container: {
    margin: "0 auto",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    padding: "20px",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginBottom: "5px",
    color: "grey",
    fontSize: "14px",
  },
  input: {
    padding: "8px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  emailDisplay: {
    display: "flex",
    flexDirection: "column",
  },
  emailText: {
    margin: "0",
    fontSize: "16px",
    color: "#000",
  },
  saveButton: {
    padding: "10px",
    fontSize: "16px",
    color: "white",
    backgroundColor: "black",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  loading: {
    textAlign: "center",
    fontSize: "18px",
    padding: "20px",
  },
};

export default UserAccount;
