import React, { useState, useEffect, useContext } from "react";
import Header from "../../elements/Header";
import { EnvContext } from "../../context/EnvContext";
import { UserContext } from "../../context/UserContext";
// MUI copy to clipboard icon
import { IconButton, Snackbar, Alert } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Help = ({ isDevMode }) => {
  //
  const { user } = useContext(UserContext);
  //
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Function to handle copy and show feedback
  const handleCopyLink = () => {
    navigator.clipboard.writeText(`http://sooperstock.com/?ui=${user?.user_code}`);
    setSnackbarOpen(true); // Open the Snackbar
  };
  return (
    <div>
      <Header title="FAQ" />
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <div style={{ textAlign: "left", padding: "20px 0" }}>
            Earn Sooper Cash by referring friends. Share the link below and your friends will get money they can spend on their next order just for signing up.
            It’s that easy!
            <br />
            <br />
            Once they buy something you get money, too! So do yourself a favor and share your unique referral link with as many friends as possible ;)
          </div>
          <br />
          <br />
          <div
            style={{
              fontSize: "26px",
              textAlign: "center",
              fontWeight: 600,
              background: "gray",
              alignSelf: "center",
              display: "flex",
              color: "white",
              borderRadius: 5,
              padding: "10px 24px",
              cursor: "pointer",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleCopyLink}
          >
            <IconButton style={{ color: "white", marginRight: 10 }} size="small">
              <ContentCopyIcon />
            </IconButton>
            Copy link
          </div>
          <div style={{ paddingTop: 15 }}>http://sooperstock.com/?ui={user?.user_code}</div>
        </div>
      </div>

      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000} // Automatically close after 3 seconds
        onClose={() => setSnackbarOpen(false)} // Close Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success" variant="filled">
          Link copied to clipboard!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Help;
