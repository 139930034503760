import React, { useState, useEffect } from "react";

const ListingPriceFormater = ({
  priceInfo,
  product,
  type,
  containerStyle,
  counterTitle,
}) => {
  let renderPrice = null;
  let renderCounter = null;
  let renderCounterWithFlames = null;

  switch (priceInfo?.priceType) {
    case "sooperdeal_price":
      renderPrice = (
        <div style={styles.addToBag.priceOffer}>
          <span style={styles.addToBag.originalPrice}>
            ${priceInfo?.listing_price}
          </span>
          <span style={styles.addToBag.salePrice}>
            ${priceInfo?.finalPrice}
          </span>
        </div>
      );
      renderCounterWithFlames = (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {counterTitle && (
            <div
              style={{
                fontSize: 11,
                opacity: 0.8,
                textTransform: "uppercase",
                flex: 1,
              }}>
              {counterTitle}
            </div>
          )}
          <div style={{ ...containerStyle, flex: 1, display: "flex" }}>
            <dotlottie-player
              src="https://lottie.host/02410fb0-fea9-4f42-8988-2b1fdbee0e1c/Ub51tC0G7m.json"
              background="transparent"
              speed="1"
              loop
              autoplay
              style={{ height: "18px", width: "12px" }}></dotlottie-player>
            <div style={{ padding: "0px 0px", fontWeight: "600" }}>
              <CounterComponent product={product} />
            </div>
            <dotlottie-player
              src="https://lottie.host/02410fb0-fea9-4f42-8988-2b1fdbee0e1c/Ub51tC0G7m.json"
              background="transparent"
              speed="1"
              loop
              autoplay
              style={{ height: "18px", width: "12px" }}></dotlottie-player>
          </div>
        </div>
      );
      renderCounter = <CounterComponent product={product} />;
      break;

    case "offer_price":
      renderPrice = (
        <div
          style={{
            ...styles.addToBag.priceOffer,
            color: product?.listing_stock_amount === 0 ? "silver" : "#1fd400",
          }}>
          <span style={styles.addToBag.originalPrice}>
            ${priceInfo?.listing_price}
          </span>
          <span style={styles.addToBag.salePrice}>
            ${priceInfo?.finalPrice}
          </span>
        </div>
      );
      break;

    case "listing_price":
      renderPrice = (
        <span
          style={{
            ...styles.addToBag.price,
            color: product?.listing_stock_amount === 0 ? "silver" : "#1fd400",
          }}>
          ${priceInfo?.finalPrice}
        </span>
      );
      break;

    default:
      renderPrice = (
        <span
          style={{
            ...styles.addToBag.price,
            color: product?.listing_stock_amount === 0 ? "silver" : "#1fd400",
          }}>
          ${priceInfo?.finalPrice}
        </span>
      );
      break;
  }

  // Render the price based on the type
  if (type === "prices") {
    return (
      <div style={{ ...containerStyle, position: "relative", zIndex: 1 }}>
        {renderPrice}
      </div>
    );
  }

  if (type === "sooperdeal_counter") {
    return (
      <div
        style={{
          display: "flex",
          ...containerStyle,
          flexDirection: "column",
          alignItems: "flex-start",
        }}>
        {counterTitle && (
          <div
            style={{
              fontSize: 11,
              opacity: 0.8,
              textTransform: "uppercase",
              flex: 1,
            }}>
            {counterTitle}
          </div>
        )}
        <div style={{ textAlign: "left" }}>{renderCounter}</div>
      </div>
    );
  }

  if (type === "sooperdeal_counter_with_flames") {
    return renderCounterWithFlames ? (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "yellow",
          width: "100%",
          height: "100%",
          ...containerStyle,
        }}>
        {renderCounterWithFlames}
      </div>
    ) : null;
  }

  return null;
};

export const CounterComponent = ({ product }) => {
  const [counter, setCounter] = useState("__:__:__:__");

  useEffect(() => {
    const updateCounter = () => {
      const sooperdealExpirationDate = new Date(
        product.sooperdeal_expiration_date
      );
      const now = new Date();
      const difference = sooperdealExpirationDate - now;
      const seconds = Math.floor(difference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const time = `${days}d ${hours % 24}h ${minutes % 60}m ${seconds % 60}s`;
      setCounter(time);
    };

    const interval = setInterval(updateCounter, 1000);
    return () => clearInterval(interval);
  }, [product.sooperdeal_expiration_date]);

  return (
    <div style={{ color: counter == "__:__:__:__" ? "silver" : "black" }}>
      {counter}
    </div>
  );
};

const styles = {
  /* Add to bag section */
  addToBag: {
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "50px",
      alignSelf: "flex-end", // Ensures it's positioned at the bottom of the list item
      marginTop: "auto",
      width: "100%",
      fontSize: "15px",
      fontWeight: 800,
    },

    priceOffer: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      fontWeight: 700,
    },
    originalPrice: {
      color: "gray",
      fontSize: "12px",
      textDecoration: "line-through",
      marginRight: "5px",
    },
    salePrice: {
      color: "#1fd400",
      fontWeight: 700,
    },
    price: {
      color: "#06ca49",
      fontWeight: 700,
    },
  },
};

export default ListingPriceFormater;
