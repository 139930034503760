import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC5JFSlefBl7pnJysUl4iEmRVp3S5KM8X0",
  authDomain: "sooperstock.com",
  projectId: "sooperstock-app",
  storageBucket: "sooperstock-app.appspot.com",
  messagingSenderId: "277475901921",
  appId: "1:277475901921:web:301af934e1046d2cfe12a0",
  measurementId: "G-6E04Z8KFTD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);

const environment = process.env.REACT_APP_ENV;

// Set the environment as a user property
if (analytics) {
  setUserProperties(analytics, { environment });
}

// Helper function to log events with debug_mode for staging
const logAnalyticsEvent = (eventName, params = {}) => {
  if (environment === "staging") {
    logEvent(analytics, eventName, { ...params, debug_mode: true });
  } else {
    logEvent(analytics, eventName, params);
  }
};

export { auth, db, app, analytics, logAnalyticsEvent };
