import { doc, getDoc, updateDoc, deleteDoc, increment } from "firebase/firestore";
import config from "../config";

export default async function handleQuantityChange(firestore, cartID, cartItemId, quantityChange, updateCartItems, collectionPath) {
  try {
    const itemRef = doc(firestore, `${collectionPath}Carts/CartsList/DataBase/${cartID}/Items/${cartItemId}`);
    const itemDoc = await getDoc(itemRef);

    if (!itemDoc.exists()) {
      console.error("Item does not exist!");
      return;
    }

    const itemData = itemDoc.data();
    const newQuantity = itemData.quantity + quantityChange;

    // Fetch the latest stock amount from Firestore
    const productRef = doc(firestore, `${collectionPath}Listings/ListingsList/DataBase/${itemData.seller_product_id}`);
    const productDoc = await getDoc(productRef);

    if (!productDoc.exists()) {
      console.error("Product does not exist!");
      return;
    }

    const productData = productDoc.data();

    // Check if the new quantity is valid based on stock amount
    if (quantityChange > 0 && productData.listing_stock_amount !== -1 && newQuantity > productData.listing_stock_amount) {
      alert("Not enough stock available");
      return;
    }

    if (newQuantity < 1) {
      // Define handleDeleteItem within this scope
      const handleDeleteItem = async () => {
        try {
          await deleteDoc(itemRef);
          updateCartItems((prevItems) => prevItems.filter((item) => item.id !== cartItemId));

          // This part is moved, and chaning listing_stock_amount is now in firebase functions, on the createOrder function
          {
            /*
          if (productData.listing_stock_amount !== -1) {
            await updateDoc(productRef, {
              listing_stock_amount: increment(itemData.quantity),
            });
          }
            */
          }
        } catch (error) {
          console.error("Error in handleDeleteItem:", error);
        }
      };

      await handleDeleteItem();
    } else {
      // Update item quantity
      await updateDoc(itemRef, { quantity: newQuantity });
      updateCartItems((prevItems) => prevItems.map((item) => (item.id === cartItemId ? { ...item, quantity: newQuantity } : item)));

      // Update listing stock amount
      // This part is moved, and chaning listing_stock_amount is now in firebase functions, on the createOrder function

      {
        /*
      if (productData.listing_stock_amount !== -1) {
        await updateDoc(productRef, {
          listing_stock_amount: increment(-quantityChange),
        });
      }
        */
      }
    }
  } catch (error) {
    console.error("Error in handleQuantityChange:", error);
  }
}
