import React, { useState, useEffect, useContext } from "react";
import Header from "../../elements/Header";
import { useFirestore } from "../../firebase/FirestoreContext"; // Assuming you have a Firestore context for DB access
import { getDocs, query, collection, where, deleteDoc, doc } from "firebase/firestore"; // Correct Firestore methods
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";

const NewsletterUnsubscribe = () => {
  //
  const environment = useContext(EnvContext);
  //
  const [statusMessage, setStatusMessage] = useState("");
  const db = useFirestore(); // Access Firestore from your custom context

  // environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  useEffect(() => {
    const unsubscribeEmail = async () => {
      // Get the email from the query string
      const params = new URLSearchParams(window.location.search);
      const email = params.get("mail");

      // If email is present
      if (email) {
        try {
          // Query the collection to find documents where the email field matches
          const q = query(collection(db, `${collectionPath}Newsletter/Subscribers/DataBase`), where("email", "==", email));

          const querySnapshot = await getDocs(q);

          // Check if any document matches the email
          if (!querySnapshot.empty) {
            querySnapshot.forEach(async (docSnapshot) => {
              // Delete each matching document
              await deleteDoc(doc(db, `${collectionPath}Newsletter/Subscribers/DataBase`, docSnapshot.id));
            });
            setStatusMessage("You have been successfully unsubscribed from our newsletter.");
          } else {
            setStatusMessage("Email not found.");
          }
        } catch (error) {
          console.error("Error unsubscribing email:", error);
          setStatusMessage("An error occurred while trying to unsubscribe.");
        }
      } else {
        setStatusMessage("Invalid unsubscribe link.");
      }
    };

    // Call the function to handle unsubscription
    unsubscribeEmail();
  }, [db]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "100vh",
      }}
    >
      <Header title="Legal" />
      <div
        style={{
          height: "100%",
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <p>{statusMessage}</p>
        {statusMessage === "You have been successfully unsubscribed from our newsletter." && (
          <p>
            We are sorry to see you go. If you have any feedback, please let us know at
            <a href="mailto:info@sooperstock.com" style={{ paddingLeft: "5px" }}>
              info@sooperstock.com
            </a>
            .
          </p>
        )}
      </div>
    </div>
  );
};

export default NewsletterUnsubscribe;
